import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

export default class extends Controller {

  connect() {
  }

  handleChange(event) {
    const url = this.element.dataset.url // Get the URL from the data-url attribute

    this.sendPutRequest(url)
  }

  sendPutRequest(url) {
    put(url, {
      responseKind: "turbo-stream"
    })
  }
}
