import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["hideable"]

  connect() {
    let elem = document.getElementById('alternative_design_arrangement_appendix_type')
    this.handleChange(elem)
  }

  appendixChange(e) {
    this.handleChange(e.target)
  }

  private

  handleChange(elem) {
    let labels = document.querySelectorAll('label.appendix')

    labels.forEach(label => {
      label.classList.add('hidden')
    })

    labels[elem.selectedIndex].classList.remove('hidden')

    if (elem.selectedIndex > 1) {
      this.hideableTarget.classList.remove("hidden")
    }
    else {
      this.hideableTarget.classList.add("hidden")
    }
  }
}
