import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String,
    param: String
  }

  change(e) {
    let url = new URL(window.location.protocol + "//" + window.location.host + this.urlValue)
    url.searchParams.set(this.paramValue, e.target.selectedOptions[0].value)

    get(url, {
      responseKind: "turbo-stream"
    }).then(() => {
      history.pushState({ turbolinks: true }, '', url)
    })
  }
}
