import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  handleDropdownChange(elem) {
    const dropdown_value = elem.target.value


    if (dropdown_value === "email") {
      document.getElementById('withdrawDiv').style.display = "none"
      document.getElementById('emailDiv').style.display = "block"
    } else {
      document.getElementById('withdrawDiv').style.display = "block"
      document.getElementById('emailDiv').style.display = "none"
    }
  }
}
