import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import { Controller } from '@hotwired/stimulus'

const DEFAULT_FROALA_OPTIONS = {
  key: "DUA2yE2C2D1A3A2A2B2qYFd1UQRFQIVb1MSMc2IWPNe1IFg1yD4C3D2C1C4C1G1H4B1D3==",
  attribution: false,
  charCounterCount: false,
  pastePlain: true,
  linkAlwaysBlank: true,
  imageUploadUrl : false,
  pluginsEnabled: ['align', 'colors', 'draggable', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url'],
}

export default class extends Controller {
  connect() {
    let customFieldAttributeValue = this.data.get("customFieldAttribute");
    let customFieldOwnerAttributeValue = this.data.get(
      "customFieldOwnerAttribute"
    );

    let toolbarButtons = ['paragraphFormat', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'fontSize', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', 'insertLink', 'insertTable']
    let froala_options

    if (
      this.element.classList.contains("vessel-variables") &&
      !toolbarButtons.includes("insert_variable")
    ) {
      FroalaEditor.DefineIconTemplate(
        "text_design",
        '<span style="text-align: center;">[NAME]</span>'
      );
      FroalaEditor.DefineIcon("insert_variable", {
        NAME: "Insert Variable",
        template: "text_design",
      });
      FroalaEditor.RegisterCommand(
        "insert_variable",
        {
          title: "Insert Variable",
          type: "dropdown",
          focus: false,
          undo: true,
          more_btn: true,
          refreshAfterCallback: true,
          options: {
            "%VESSEL_NAME%": "Vessel Name",
            "%IMO_NUMBER%": "IMO Number",
            "%REGISTRATION_EXPIRED_DATE%": "Registration Expired date",
            "%CERTIFICATE_NUMBER%": "Certificate Number",
            "%CERTIFICATE_TYPE%": "Certificate Type",
            "%CERTIFICATE_ISSUED_AT_DATE%": "Certificate Issued at date",
            "%CERTIFICATE_EXPIRED_AT_DATE%": "Certificate Expired at date",
            "%VESSEL_MANAGER%": "Vessel Manager",
          },
          callback: function (cmd, val) {
            this.html.insert(val);
          },
        },
        toolbarButtons.push("insert_variable")
      );
    } else if (
      this.element.classList.contains("vessel-manager-variables") &&
      !toolbarButtons.includes("insert_variable")
    ) {
      FroalaEditor.DefineIconTemplate(
        "text_design",
        '<span style="text-align: center;">[NAME]</span>'
      );
      FroalaEditor.DefineIcon("insert_variable", {
        NAME: "Insert Variable",
        template: "text_design",
      });
      FroalaEditor.RegisterCommand(
        "insert_variable",
        {
          title: "Insert Variable",
          type: "dropdown",
          focus: false,
          undo: true,
          more_btn: true,
          refreshAfterCallback: true,
          options: {
            "%VESSEL_MANAGER_NAME%": "Vessel Manager Name",
            "%IMO_NUMBER%": "IMO Number",
            "%CERTIFICATE_NUMBER%": "Certificate Number",
            "%CERTIFICATE_TYPE%": "Certificate Type",
            "%CERTIFICATE_ISSUED_AT_DATE%": "Certificate Issued at date",
            "%CERTIFICATE_EXPIRED_AT_DATE%": "Certificate Expired at date",
          },
          callback: function (cmd, val) {
            this.html.insert(val);
          },
        },
        toolbarButtons.push("insert_variable")
      );
    } else if (
      this.element.classList.contains("deficiency-inspection-variables") &&
      !toolbarButtons.includes("insert_variable")
    ) {
      FroalaEditor.DefineIconTemplate(
        "text_design",
        '<span style="text-align: center;">[NAME]</span>'
      );
      FroalaEditor.DefineIcon("insert_variable", {
        NAME: "Insert Variable",
        template: "text_design",
      });
      FroalaEditor.RegisterCommand(
        "insert_variable",
        {
          title: "Insert Variable",
          type: "dropdown",
          focus: false,
          undo: true,
          more_btn: true,
          refreshAfterCallback: true,
          options: {
            "%VESSEL_NAME%": "Vessel Name",
            "%IMO_NUMBER%": "IMO Number",
            "%VESSEL_MANAGER%": "Vessel Manager",
            "%LINK_TO_DEFICIENCY_INSPECTION%": "Link to Deficiency Inspection",
            "%DEFICIENCY_INSPECTION_DUE_DATE%": "Deficiency Inspection Due date",
          },
          callback: function (cmd, val) {
            this.html.insert(val);
          },
        },
        toolbarButtons.push("insert_variable")
      );
    } else if (
      this.element.classList.contains("inspection-variables") &&
      !toolbarButtons.includes("insert_variable")
    ) {
      FroalaEditor.DefineIconTemplate(
        "text_design",
        '<span style="text-align: center;">[NAME]</span>'
      );
      FroalaEditor.DefineIcon("insert_variable", {
        NAME: "Insert Variable",
        template: "text_design",
      });
      FroalaEditor.RegisterCommand(
        "insert_variable",
        {
          title: "Insert Variable",
          type: "dropdown",
          focus: false,
          undo: true,
          more_btn: true,
          refreshAfterCallback: true,
          options: {
            "%VESSEL_NAME%": "Vessel Name",
            "%IMO_NUMBER%": "IMO Number",
            "%VESSEL_MANAGER%": "Vessel Manager",
            "%LINK_TO_INSPECTION%": "Link to Inspection",
            "%INSPECTION_DUE_DATE%": "Inspection Due date",
          },
          callback: function (cmd, val) {
            this.html.insert(val);
          },
        },
        toolbarButtons.push("insert_variable")
      );
    } else if (
      this.element.classList.contains("letter_template") &&
      !toolbarButtons.includes("insert_variable")
    ) {
      FroalaEditor.DefineIconTemplate(
        "text_design",
        '<span style="text-align: center;">[NAME]</span>'
      );
      FroalaEditor.DefineIcon("insert_variable", {
        NAME: "Insert Variable",
        template: "text_design",
      });
      FroalaEditor.RegisterCommand(
        "insert_variable",
        {
          title: "Insert Variable",
          type: "dropdown",
          focus: false,
          undo: true,
          more_btn: true,
          refreshAfterCallback: true,
          options: this.letter_template_options(
            customFieldAttributeValue,
            customFieldOwnerAttributeValue
          ),
          callback: function (cmd, val) {
            this.html.insert(val);
          },
        },
        toolbarButtons.push("insert_variable")
      );
    }

    froala_options = $.extend({}, DEFAULT_FROALA_OPTIONS, {
      toolbarButtons: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsXS: toolbarButtons,
      useClasses: this.element.classList.contains('froala-inline')
    });

    new FroalaEditor(this.element, froala_options)
  }

  letter_template_options(customFieldsVessel, customFieldsOwner) {
    let options = {
      "%vessel_name%": "Vessel name",
      "%owner_names%": "Owner name(s)",
      "%recipient%": "Current recipient full name",
      "%today%": "Date today",
      "%official_number%": "Official number",
      "%imo_number%": "IMO number",
      "%mmsi_number%": "MMSI number",
      "%registration_expiry_date%": "Registration expiry date",
      "%registrar_full_name%": "Name of Registrar sending the letter"
  };

    JSON.parse(customFieldsVessel).forEach((key) => {
      options[key] = key.replace(/%/g, "").toUpperCase().replace(/_/g, " ");
    });

    JSON.parse(customFieldsOwner).forEach((key) => {
      options[key] = key.replace(/%/g, "").toUpperCase().replace(/_/g, " ");
    });
    return options;
  }
}
