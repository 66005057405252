import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  table

  connect() {
    this.table = this.element
    this.setMoreOptionTable()
    this.assignClickEvents()
  }

  private

  setMoreOptionTable() {
    var footer, new_colspan, new_row, tableRows, x

    tableRows = $(this.table).find('tbody tr')
    x = 0

    while (x < tableRows.length) {
      if (this.deadRow(tableRows[x])) {
        tableRows[x].style.display = 'none'
      }
      x++
    }

    if ($(this.table).find('.see-more-click').length === 0) {
      if (this.hasOldElement(this.table)) {
        footer = this.table.createTFoot()
        new_colspan = this.table.rows[0].cells.length
        new_row = footer.insertRow(-1)
        new_row.innerHTML = "<td colspan=\"" + new_colspan + "\" style=\"background-color: #faffe7\"><span class=\"btn-link see-more-click\">Show all records including historical documents</span></td>"
      }
    }
  }

  deadRow(row) {
    return (
      row.innerHTML.match('<s>') !== null ||
      row.classList.contains('strike') ||
      row.classList.contains('stripe')
    );
  }

  assignClickEvents() {
    var i, seeMoreClick
    seeMoreClick = document.getElementsByClassName('see-more-click')
    i = 0
    while (i < seeMoreClick.length) {
      seeMoreClick[i].addEventListener('click', (event) => {
        this.alertClick(event)
      })
      i++
    }
  }

  alertClick(event) {
    this.createMarriedElements(event.target)
  }

  hasOldElement() {
    var i, result
    i = 0
    result = false
    while (i < this.table.rows.length) {
      if (this.deadRow(this.table.rows[i])) {
        result = true
      }
      i++
    }
    return result
  }

  createMarriedElements(initialElement) {
    var i, results, results1, x;
    if (initialElement.innerHTML === "Show all records including historical documents") {
      initialElement.innerHTML = "Hide historical documents";
      i = 0;
      results = [];
      while (i < this.table.rows.length) {
        this.table.rows[i].style.display = "table-row";
        results.push(i++);
      }
      return results;
    } else {
      initialElement.innerHTML = "Show all records including historical documents";
      x = 0;
      results1 = [];
      while (x < this.table.rows.length) {
        if (this.deadRow(this.table.rows[x])) {
          this.table.rows[x].style.display = "none";
        }
        results1.push(x++);
      }
      return results1;
    }
  }
}