// app/javascript/controllers/autocomplete_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    data: Array
  };

  connect() {
    this.setupAutocomplete();
    this.addClickOutsideListener();
  }

  disconnect() {
    this.removeClickOutsideListener();
  }

  setupAutocomplete() {
    const input = this.element.querySelector("input");

    if (!input) return;

    input.addEventListener("input", (event) => {
      const query = event.target.value.toLowerCase();
      const suggestions = this.dataValue.filter(item => item.toLowerCase().includes(query));
      this.showSuggestions(suggestions);
    });

    input.addEventListener("focus", () => {
      this.showSuggestions(this.dataValue);
    });
  }

  showSuggestions(suggestions) {
    this.hideSuggestions(); // Remove previous suggestions

    if (suggestions.length === 0) return;

    const input = this.element.querySelector("input");
    const inputRect = input.getBoundingClientRect();

    const list = document.createElement("ul");
    list.className = "ui-autocomplete ui-front ui-menu ui-widget ui-widget-content";
    list.style.position = "absolute";
    list.style.top = `${inputRect.bottom}px`;
    list.style.left = `${inputRect.left}px`;
    list.style.width = `${inputRect.width}px`; // Match the width of the input field

    suggestions.forEach((suggestion) => {
      const item = document.createElement("li");
      item.className = "ui-menu-item"; // Add class to each <li>
      item.textContent = suggestion;

      // Handle hover state
      item.addEventListener("mouseover", () => {
        item.classList.add("ui-state-focus");
      });

      item.addEventListener("mouseout", () => {
        item.classList.remove("ui-state-focus");
      });

      item.addEventListener("click", () => {
        input.value = suggestion;
        this.hideSuggestions();
      });

      list.appendChild(item);
    });

    document.body.appendChild(list); // Append to the body
  }

  hideSuggestions() {
    const existingList = document.body.querySelector(".ui-autocomplete");
    if (existingList) {
      existingList.remove();
    }
  }

  addClickOutsideListener() {
    this.handleClickOutside = (event) => {
      if (!this.element.contains(event.target) && !document.querySelector(".ui-autocomplete")?.contains(event.target)) {
        this.hideSuggestions();
      }
    };
    document.addEventListener("click", this.handleClickOutside);
  }

  removeClickOutsideListener() {
    document.removeEventListener("click", this.handleClickOutside);
  }
}
