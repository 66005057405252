import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  connect() {
    this.initializeDateTimePicker(this.element)
    this.dateIssueField = this.determineActiveDateField()
    if (this.dateIssueField) {
      this.setupDateChangeHandler()
      this.setupDueDateFocusHandler()
    }
  }

  determineActiveDateField() {
    if (document.getElementById('invoice_date_issue')) {
      return 'invoice_date_issue'
    } else if (document.getElementById('quote_date_issue')) {
      return 'quote_date_issue'
    } else {
      return null
    }
  }

  initializeDateTimePicker(elem) {
    const options = {
      locale: moment.locale('en', { week: { dow: 1 } }),
      useCurrent: false
    };
    this.disableAutocomplete(elem)
    $(elem).datetimepicker(options)
  }

  disableAutocomplete(field) {
    field.setAttribute('autocomplete', 'off')
    field.setAttribute('readonly', 'readonly') // Set to readonly initially
    setTimeout(() => {
      field.removeAttribute('readonly') // Remove readonly after a short delay
    }, 1000)
  }

  setupDateChangeHandler() {
    const dueDateField = this.dateIssueField.replace('issue', 'due')
    $(`#${this.dateIssueField}`).on('dp.change', e => {
      const dateValue = e.target.value
      if (dateValue) {
        const newDueDate = moment(dateValue, $(`#${e.target.id}`).data('dateFormat')).add(30, 'days').format($(`#${e.target.id}`).data('dateFormat'))
        $(`#${dueDateField}`).val(newDueDate)
      }
    });
  }

  setupDueDateFocusHandler() {
    const dueDateField = this.dateIssueField.replace('issue', 'due')
    const issueDateField = this.dateIssueField
    $(`#${dueDateField}`).on('focus', function() {
      if (!document.getElementById(issueDateField).value) {
        const today = new Date()
        const futureDate = new Date(today.setDate(today.getDate() + 30))
        const formattedDate = moment(futureDate).format($(`#${dueDateField}`).data('dateFormat'))
        $(this).val(formattedDate)
      }
    });
  }

  filterTable(e) {
    const url = new URL(window.location.href)
    url.searchParams.set(e.target.name, e.target.value)

    get(url, { responseKind: "turbo-stream" }).then(() => {
      history.pushState({ turbolinks: true }, '', url)
      this.inputTarget.focus()
    });
  }

  disconnect() {
    $(this.element).datetimepicker('destroy')
  }
}
