import { Controller } from "@hotwired/stimulus"
import 'bootstrap/js/modal.js'

export default class extends Controller {
  connect() {
    if ($(this.element).data('auto-open') != false)
      $(this.element).modal('show')

    document.addEventListener("turbo:before-fetch-response", (event) => { this.close(event) })
  }

  close(event) {
    if (event.detail.fetchResponse.response.ok)
      $(this.element).modal('hide')
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-response", this.close.bind(this))
  }
}
