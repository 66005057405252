import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  connect() {
    this.linkTargets.forEach(link => {
      link.addEventListener('click', this.setAnchor);
    })
  }

  disconnect() {
    this.linkTargets.forEach(link => {
      link.removeEventListener('click', this.setAnchor)
    })
  }

  setAnchor(event) {
    event.preventDefault()
    const link = event.target
    const anchor = link.getAttribute('href').replace('#', '')
    window.location.hash = anchor
  }
}
