import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.timeout = null
  }

  search(e) {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      let url = this.getURL()

      url.searchParams.set('query', e.target.value)

      get(url, {
        responseKind: "turbo-stream"
      }).then(() => {
        history.pushState({ turbolinks: true }, '', url)
      })

      this.timeout = null
    }, 300)
  }

  getURL() {
    if (document.querySelector('#workflow-templates-index') || (document.querySelector('.tab-container li.active a') && document.querySelector('.tab-container li.active a').dataset.url))
      return new URL(document.querySelector('.tab-container li.active a').dataset.url)
    else
      return new URL(window.location.href)
  }
}
