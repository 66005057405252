import { Controller } from '@hotwired/stimulus'

const DEFAULT_DATATABLE_OPTIONS = {
  language: {
    lengthMenu: '<select>' + '<option value=20>20 records</option>' + '<option value=50>50 records</option>' + '<option value=100>100 records</option>' + '<option value=200>200 records</option>' + '</select>',
    paginate: {
      first: "<i class='fa fa-long-arrow-left'></i> Previous</a>",
      last: "Next <i class='fa fa-long-arrow-right'></i>"
    }
  },
  pagingType: "bootstrap",
  lengthMenu: [[20, 50, 100, 200], [20, 50, 100, 200]],
  pageLength: 100,
  dom: '<"top">rt<"bottom"iflp><"clear">',
  bDeferRender: true,
  columnDefs: $("#german-dates").length > 0 ? [
    {
      type: 'de_date',
      targets: 3
    }
  ] : void 0
}

export default class extends Controller {
  connect() {
    $(this.element).DataTable(DEFAULT_DATATABLE_OPTIONS)
  }
}
