import { Application } from '@hotwired/stimulus'
import CheckboxSelectAll from "stimulus-checkbox-select-all"
import { registerControllers } from 'stimulus-vite-helpers'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

// Start the Stimulus application.
const application = Application.start()
application.register("checkbox-select-all", CheckboxSelectAll)
application.register('textarea-autogrow', TextareaAutogrow)

// Controller files must be named *_controller.js.
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)
