import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).fileupload({
      dropZone: null,
      url: this.element.dataset.url,
      done: function (e, data) {
        const contentType = data.jqXHR.getResponseHeader('Content-Type')
        if (contentType && contentType.includes('turbo-stream')) {
          Turbo.renderStreamMessage(data.result);
        } else {
          if (data.result.warning) {
            window.toastr['warning'](data.result.warning);
          } else {
            window.toastr['success']('File has been uploaded successfully')
            window.location.reload()
          }
        }
      }
    })
  }
}
