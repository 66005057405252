import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  updateNote(e) {
    let url = e.target.dataset.url
    let value = e.target.value

    patch(url, {
      body: {
        vessel_check_list: {
          note: value
        }
      },
      contentType: "application/json",
      responseKind: "json"
    }).then(() => {
      toastr.options = {
        positionClass: "toast-bottom-left",
        closeButton: true,
        timeOut: "5000",
        extendedTimeOut: "1000",
        progressBar: true
      }
      window.toastr['success']('Changes have been saved')
    })
  }

  updateCheckbox(e) {
    let url = e.target.dataset.url

    patch(url, {
      contentType: "application/json",
      responseKind: "json"
    }).then(() => {
      toastr.options = {
        positionClass: "toast-bottom-left",
        closeButton: true,
        timeOut: "5000",
        extendedTimeOut: "1000",
        progressBar: true
      }

      window.toastr['success']('Changes have been saved')
    })
  }
}
