import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.fetchValues(this.element)
  }

  handleFilterName(e) {
    this.fetchValues(e.target)
  }

  private

  fetchValues(elem) {
    let url_with_params = new URL(elem.dataset.url)
    url_with_params.searchParams.set('name', elem.value)

    get(url_with_params, {
      responseKind: "turbo-stream"
    })
  }
}
