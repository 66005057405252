import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const element = $(this.element)
    const placeholder = element.data('placeholder')
    const isAjax = element.hasClass('select2-ajax-inspection') || element.hasClass('select2-ajax-recipient')
    console.log(isAjax)

    if (element.hasClass('select2')) {
      const options = { placeholder: placeholder || undefined }

      if (isAjax) {
        options.ajax = {
          allowClear: true,
          delay: 250,
          url: () => element.data("autocomplete"),
          dataType: "json",
          minimumInputLength: 1,
          processResults: (data) => ({ results: data }),
          cache: true,
        }
        options.escapeMarkup = (m) => m
      }

      element.select2(options)
    }

    // Listen for change events on ownerable-search selects
    if (this.element.classList.contains('ownerable-search')) {
      element.change(() =>
        this.updateOwnerableFields(element)
      )
      this.updateOwnerableFields(element)
    }
  }

  disconnect() {
    if (this.element.classList.contains('select2-hidden-accessible')) {
      $(this.element).select2('destroy')
    }

    if (this.element.classList.contains('ownerable-search')) {
      this.element.removeEventListener('change', this.updateOwnerableFields)
    }
  }

  updateOwnerableFields(elem) {
    console.log(elem.val().split('|||')[0], elem.val().split('|||')[1])
    elem.closest('form').find('.ownerable_id').val(elem.val().split('|||')[0])
    elem.closest('form').find('.ownerable_type').val(elem.val().split('|||')[1])
  }
}
