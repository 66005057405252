import { Turbo } from "@hotwired/turbo-rails"
import TurboPower from 'turbo_power'

import '~/controllers'

Turbo.session.drive = false

Turbo.setConfirmMethod((payload) => {
  let dialog = document.getElementById('turbo-confirm')
  let turbo_close = dialog.querySelector('.turbo-close')
  let display
  let cssButtonClass

  let data = JSON.parse(payload)
  dialog.querySelector('.modal-body p span').textContent = data.message
  dialog.querySelector('.modal-title').textContent = data.title || 'Confirm'
  dialog.querySelector('.cancel').textContent = data.cancel_button_text || 'Cancel'
  dialog.querySelector('.confirm').textContent = data.confirm_button_text || 'OK'
  dialog.querySelector('.modal-header').classList = data.type ? 'modal-header alert-' + data.type : 'modal-header modal-header-delete'
  if (data.type != undefined) {
    display = 'none'
    cssButtonClass = "btn-" + data.type
  }
  else {
    display = 'inline'
    cssButtonClass = 'btn-danger'
  }

  dialog.querySelector('.proceed').style.display = display
  dialog.querySelector('.confirm').classList.add(cssButtonClass)

  dialog.showModal()

  return new Promise((resolve) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue == 'ok')
    }, { once: true })

    turbo_close.addEventListener('click', () => {
      dialog.close()
    })
  })
})

TurboPower.initialize(Turbo.StreamActions)
